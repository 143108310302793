import React, { type FC } from 'react'
import { Grid, Skeleton, Typography, Box } from '@mui/material'

const CustomerDetailsPanel: FC<any> = ({ customer }) => {
  console.log(customer);
  return (
    <Box>
      <Typography variant="h6" align='left'>Customer Information</Typography>
      <br/>
      {customer ?
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {customer
            ? (
            <>
              <Typography variant="body1" align="left"><strong>Identity:</strong> {customer.CorporateID}</Typography>
              <Typography variant="body1" align="left"><strong>Name:</strong> {customer.Name}</Typography>
              <Typography variant="body1" align="left"><strong>Business:</strong> {customer.Company}</Typography>
              <Typography variant="body1" align="left"><strong>Email Address:</strong> {customer.CorporateEmail}</Typography>
            </>
              )
            : (
              <>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </>
              )}
        </Grid>
        <Grid item xs={6}>
          {customer
            ? (
              <>
              <Typography variant="body1" align="left"><strong>Site ID:</strong> {customer.SiteID}</Typography>
              <Typography variant="body1" align="left"><strong>Surname:</strong> {customer.Surname}</Typography>
              <Typography variant="body1" align="left"><strong>Mobile:</strong> {customer.PhoneMobile}</Typography>
              <Typography variant="body1" align="left"><strong>Contact:</strong> {customer.Site}</Typography>
            </>
              )
              : (
                <>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </>
              )}
        </Grid>  
      </Grid> :
        <>
        <Box display="flex" flexDirection="column" justifyContent="center" flexGrow={1} alignItems="center" height='100%'>
        <Typography variant="h5" color="textSecondary" align='center'>
          No Customer Selected
        </Typography>
        <Typography variant="body1" color="textSecondary" align='center' style={{ marginTop: '8px' }}>
          Please search for a customer to view their details.
        </Typography>
      </Box>
        </>}
    </Box>
  )
}

export default CustomerDetailsPanel
