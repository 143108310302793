import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { ConfigProvider } from './components/ConfigContext/Config'
import { ThemeProvider, createTheme } from '@mui/material/styles'

// warning being generated by botframework. they are working on a fix for this https://github.com/microsoft/BotFramework-WebChat/issues
const theme = createTheme({
  palette: {
    // Define your theme
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root')
)
root.render(
  <ThemeProvider theme={theme}>
  <ConfigProvider>
    <App />
  </ConfigProvider>
  </ThemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
