import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import ConfigContext from '../ConfigContext/Config';
import { UserContext } from '../Auth/Auth';

export default function PersistentDrawerLeft({ formData }) {
  const { properties } = useContext(ConfigContext)
  const { logout } = useContext(UserContext);
  const directlineUrl = properties.baseUrls.directline + '/api/saas/' + properties.directline.key
  const [incidentNumber, setIncidentNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const commonStyles = {
    mr: 1,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '15px',
      },
      '&:hover fieldset': {
        borderColor: 'currentColor',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'currentColor',
      }
    },
    '& .MuiOutlinedInput-input': {
      borderRadius: '15px',
      backgroundColor: 'white',
      color: 'black',
    }
  };

  const handleInputChange = (value, setter, otherSetters) => {
    setter(value);
    if (value.length > 3) {
      otherSetters.forEach(set => set(""));
    }
  };

  const handleLogoutClick = () => {
    logout();
    window.location.reload();
  };

  const handleRefreshClick = () => {
    window.location.reload();
  };

  const handleSearchClick = () => {
    let apiName = ''; 
    let identifierValue = '';

    if (incidentNumber) {
        apiName = 'findByIncident';
        identifierValue = incidentNumber;
    } else if (mobileNumber) {
        apiName = 'findByMsisdn';
        identifierValue = mobileNumber;
    } else if (emailAddress) {
        apiName = 'findByEmail';
        identifierValue = emailAddress;
    } else {
        console.log("No input provided!");
        return;
    }
    axios
        .post(`${directlineUrl}/directline/event`, {
            name: apiName,
            data: {
                identifier: identifierValue
            },
            userId: formData.contact.replace('+', ''),
        })
        .then((response) => {
            console.log(response);
        })
        .catch((error) => {
            console.error('Error sending event:', error);
        });
  };

  return (
    <Box sx={{ display: 'flex', height: '60px', overflow: 'hidden' }}>
      <MuiAppBar
        position="fixed"
        sx={{
          backgroundColor: '#36454F',
          height: '60px',
          transition: (theme) => theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          })
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Agent Assist
          </Typography>
          <TextField 
            variant="outlined" 
            size="small" 
            placeholder="Incident Number"
            value={incidentNumber}
            onChange={e => handleInputChange(e.target.value, setIncidentNumber, [setMobileNumber, setEmailAddress])}
            sx={commonStyles}
          />
          <TextField 
            variant="outlined" 
            size="small" 
            placeholder="Mobile Number"
            value={mobileNumber}
            onChange={e => handleInputChange(e.target.value, setMobileNumber, [setIncidentNumber, setEmailAddress])}
            sx={commonStyles}
          />
          <TextField 
            variant="outlined" 
            size="small" 
            placeholder="Email Address"
            value={emailAddress}
            onChange={e => handleInputChange(e.target.value, setEmailAddress, [setIncidentNumber, setMobileNumber])}
            sx={commonStyles}
          />
          <Button 
            onClick= {handleSearchClick}
            variant="contained" 
            sx={{
                backgroundColor: '#9ca2a6',
                color: 'white',
                '&:hover': {
                    backgroundColor: '#7b8084',
                },
                marginRight: '10px'
            }}
          >
              Search
          </Button>
          <Button 
            onClick= {handleRefreshClick}
            variant="contained" 
            sx={{
                backgroundColor: '#9ca2a6',
                color: 'white',
                '&:hover': {
                    backgroundColor: '#7b8084',
                },
                marginRight: '10px'
            }}
          >
              Refresh
          </Button>
          <Button 
            onClick= {handleLogoutClick}
            variant="contained" 
            sx={{
                backgroundColor: '#FF7F7F',
                color: 'white',
                '&:hover': {
                    backgroundColor: '#E0115F',
                }
            }}
          >
              Logout
          </Button>
        </Toolbar>
      </MuiAppBar>
    </Box>
  )
}
