import React, { type FC } from 'react'
import { Typography, Grid, Box, Skeleton } from '@mui/material'

interface Template {
  Description: string
  Name: string
  StepID: string
}

interface IncidentDetailsProps {
  details: any
  templates: Template[]
  setDetails: any
  formData: any
  selectIsLoading: boolean
}

type IncidentData = {
  incidentNumber: string;
  description: string;
  templateName?: string;
  templateSiteID?: string;
  templateDescription?: string;
};

const IncidentDetails: FC<IncidentDetailsProps> = ({ details, formData, setDetails, templates, selectIsLoading }) => {
  return (
    <>
    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="8px">
      <Typography variant="h6" align='left'>High Incident Details</Typography>
    </Box>
      <Box style={{ height: 'calc(100% - 33px)', width: '100%', overflow: 'auto' }}>
        {details ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1" color="textSecondary" align='left'><strong>Number:</strong> {details.Number}</Typography>
                <Typography variant="body1" color="textSecondary" align='left'><strong>Submit Date:</strong> {details.SubmitDate.substring(0, 10).replace('_', '')}</Typography>
                <Typography variant="body1" color="textSecondary" align='left'><strong>Status:</strong> {details.Status}</Typography>
                <Typography variant="body1" color="textSecondary" align='left'><strong>Summary:</strong> {details.Summary}</Typography>
                <Typography variant="body1" color="textSecondary" align='left'><strong>Site:</strong> {details.Site}</Typography>
                <Typography variant="body1" color="textSecondary" align='left'><strong>Priority:</strong> {details.Priority}</Typography>
                <Typography variant="body1" color="textSecondary" align='left'><strong>Company:</strong> {details.Company}</Typography>
              </Grid>
              <Grid item xs={6}>
                <>
                  <Typography variant="body1" color="textSecondary" align='left'><strong>Worklog Number:</strong> {details.WorkLogNumber}</Typography>
                  <Typography variant="body1" color="textSecondary" align='left'><strong>Worklog Description:</strong> {details.WorkLogDescription}</Typography>
                  <Typography variant="body1" color="textSecondary" align='left'><strong>Worklog Detailed Description:</strong> {details.WorkLogDetailedDescription}</Typography>
                </>
              </Grid>
            </Grid>
          </>
        ) : (
          selectIsLoading ? (
            <Grid container spacing={2}>
            <Grid item xs={6}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
          </Grid>
          ) : (
            <Box display="flex" flexDirection="column" justifyContent="center" flexGrow={1} alignItems="center" height='100%'>
            <Typography variant="h5" color="textSecondary" align='center'>
              No Incident Selected
            </Typography>
            <Typography variant="body1" color="textSecondary" align='center' style={{ marginTop: '8px' }}>
              Please select an incident from the list to view its details.
            </Typography>
          </Box>
          ))
        }
      </Box>
    </>
  )
}
export default IncidentDetails
