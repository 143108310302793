import React, { useState, useEffect, useContext, useReducer } from 'react';
import { UserContext } from '../Auth/Auth';
import configContext from '../ConfigContext/Config';
import _ from 'lodash';

//components
import Header from '../header/header';
import Form from '../form/form';
import Modal from '../modal/modal';
import Sidebar from '../Sidebar/Sidebar';

//css
import './home.css';
import Loader from '../loader/loader';
import OtpForm from '../otp/otpForm';
import { InitiateAuthCommandOutput } from '@aws-sdk/client-cognito-identity-provider';
import TimeoutModal from '../modal/timeout-modal';
import { Grid } from '@mui/material';
import ReactWebChatPage from '../ReactWebChatPage/ReactWebChatPage';

function Home() {
  const { features, styles, properties } = useContext(configContext);
  document.documentElement.style.setProperty('--main-tertiary-color', styles.mainTertiaryColor);
  document.documentElement.style.setProperty('--login-background', styles.login.background);
  document.documentElement.style.setProperty('--chat-background', styles.chatBackground);
  document.documentElement.style.setProperty('--main-sub-color', styles.mainSubColor);
  document.documentElement.style.setProperty('--header-color', styles.header.background);
  document.documentElement.style.setProperty('--header-card-color', styles.header.cardBackground);
  document.documentElement.style.setProperty('--header-card-text-color', styles.header.cardTextColor);
  document.documentElement.style.setProperty('--login-screen', styles.login.screen);
  document.documentElement.style.setProperty('--login-shape1', styles.login.shape1);
  document.documentElement.style.setProperty('--login-shape2', styles.login.shape2);
  document.documentElement.style.setProperty('--login-shape3', styles.login.shape3);
  document.documentElement.style.setProperty('--login-shape4', styles.login.shape4);

  const [loading, setLoading] = useState(false);
  const [formSubmitted, setSubmit] = useState(false);
  const [error, setError] = useState('');
  const [otpSuccessful, setOTP] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [session, setSession] = useState<InitiateAuthCommandOutput>();
  const [username, setUsername] = useState('');
  const { authSignUp, initiateAuth, respondFirstChallenge, respondSecondChallenge, getSession, logout } = useContext(UserContext);
  const botImageModule = require(`../../assets/${properties.id}/bot.png`);
  const botImage = botImageModule.default;
  const [formData, setFormData] = useState(() => {
    const chatSessionString = localStorage.getItem('chat-session');
    const chatSession = chatSessionString ? JSON.parse(chatSessionString) : {};
    return {
      contact: chatSession.contact || '',
      type: chatSession.type || ''
    };
  });
  const botStyles = {
    botAvatarInitials: styles.botAvatarInitials,
    accent: '#00809d',
    botAvatarBackgroundColor: "#FFFFFF",
    botAvatarImage: botImage,
    bubbleBorderRadius: 20,
    bubbleFromUserBackground: '#ffffff',
    bubbleFromUserBorderRadius: 20,
    sendBoxButtonColorOnHover: '#BD302C',
    cardEmphasisBackgroundColor: '#c61f48',
    cardPushButtonBackgroundColor: 'black',
    cardPushButtonTextColor: 'White',

  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const currentSession = localStorage.getItem('validSession');
        console.log(currentSession);
        if (currentSession) {
          setOTP(true);
        } else {
          setOTP(false);
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const onHandleFormSubmit = async (form: any) => {
    setLoading(true);
    setFormData(form);
    localStorage.setItem('chat-session', JSON.stringify(form));
    if (features.OTP) {
      authSignUp({ name: form.name, username: form.contact, type: form.type, companyType: form.companyType })
      .then(data => {
        initiateAuth({ username: form.contact, companyType: form.companyType }).then((data: any) => {
          respondFirstChallenge(data, form.type).then((data: any) => {
            setSession(data);
            setSubmit(true);
            setLoading(false);
          }).catch(error => {
            setError(error.message);
            setLoading(false);
          })
        }).catch(error => {
          setError(error.message);
          setLoading(false);
        })
      }).catch(error => {
        if (error.message === "PreSignUp failed with error User exists.") {
          initiateAuth({ username: form.contact, companyType: form.companyType }).then((data: any) => {
            setUsername(data.ChallengeParameters?.USERNAME);
            respondFirstChallenge(data, form.type).then((data: InitiateAuthCommandOutput) => {
              setSession(data);
              setSubmit(true);
              setLoading(false);
            }).catch(error => {
              setLoading(false);
            })
          }).catch(error => {
            setLoading(false);
          })
        } else if (error.message === "Failed to fetch") {
          setLoading(false);
          setError("NET-001 Please check whether you are connected to the internet.")
        } else {
          setError(error.message);
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
      setOTP(true)
    }
  }

  const onHandleOTPSubmit = async (data: any) => {
    setLoading(true);
    respondSecondChallenge(session, username, formData.contact, data.otp, formData.type)
    .then(data => {
      setSubmit(false);
      setOTP(true);
      setLoading(false);
    }).catch(error => {
      console.log("otpsubmit error:", error);
      setLoading(false);
    })
  }

  const onhandleShowModal = () => {
    setShowModal(true);
  }

  const onhandleShowTimeoutModal = () => {
    setShowTimeoutModal(true);
  }

  const onHandleLogOut = async () => {
    logout();
    setSubmit(false);
    setOTP(false);
    setShowModal(false);
  }

  const onCloseTimeoutModal = () => {
    setShowTimeoutModal(false);
  }

  const onRefreshSession = () => {
    setShowModal(false);
    window.location.reload();
  }

  const onErrorClose = () => {
    setError('');
  }

  return (
    <React.Fragment>
      <Loader show={loading} />
        {otpSuccessful ?
          <React.Fragment>
            <Sidebar formData={formData}/>
            <ReactWebChatPage
              botStyles={botStyles}
              formData={formData}
              message={''}
              onShowModal={onhandleShowModal}
              onShowTimeoutModal={onhandleShowTimeoutModal} />
            <Modal show={showModal} onRefreshSession={onRefreshSession} onLogout={onHandleLogOut} />
          </React.Fragment>
          :
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Header handleLogOut={onHandleLogOut} formSubmitState={otpSuccessful} onRefreshSession={onRefreshSession} />
            </Grid>
            <Grid item xs={9}>
            {formSubmitted ?
                <OtpForm formSubmit={onHandleOTPSubmit} />
                :
                <Form formSubmit={onHandleFormSubmit} onError={error} onErrorClose={onErrorClose} />
            } </Grid>
          </Grid>
        }
      <Modal show={showModal} onRefreshSession={onRefreshSession} onLogout={onHandleLogOut} />
      <TimeoutModal show={showTimeoutModal} onClose={onCloseTimeoutModal} onLogout={onHandleLogOut} />
    </React.Fragment>
  );
}

export default Home;
