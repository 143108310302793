import React, { useContext } from 'react'

// import footerXiva from '../../assets/footer-xiva.svg';

import './header.css'
import configContext from '../ConfigContext/Config'

interface Props {
  handleLogout: Function
}

const Header = (props: any) => {
  const { properties, styles } = useContext(configContext)
  const companyLogoImageModule = require(`../../assets/${properties.id}/logo.png`)
  const companyLogoImage = companyLogoImageModule.default
  return (
    <div className="sidemenu">
        <div className="sidemenu__content">
            <div className="card">
                <div className="card__image-container">
                    <img className="card__image" src={companyLogoImage} alt={`company logo | ${properties.appName}`} />
                </div>
                <svg className="card__svg" viewBox="0 0 800 500">
                    <path d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400 L 800 500 L 0 500" stroke="transparent" fill={styles.header.cardBackground}/>
                    <path className="card__line" d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400" stroke="pink" strokeWidth="3" fill="transparent"/>
                </svg>

                <div className="card__content">
                    <h1 className="card__title">{properties.appName}</h1>
                    <p>I am {properties.appName}'s personal assistant, and I'm available to help you every day of the year.</p>
                </div>
            </div>
            <div className="sidemenu__footer">
            {props.formSubmitState
              ? <>
            <div className='footer-btn-container'>
                <button onClick={props.handleLogOut} className="logout-btn">Log Out</button>
                <button onClick={props.onRefreshSession} className="logout-btn">Refresh</button>
            </div>
            </>
              : ''}
                {/* <object type='image/svg+xml' data={footerXiva}></object> */}
            </div>
            <div className='mobile__header'>
                <h1 className="mobile__title">{properties.appName}</h1>
                {props.formSubmitState ? <div><button onClick={props.handleLogOut} className="logout-btn">Log Out</button></div> : ''}
            </div>
        </div>
    </div>

  )
}

export default Header
