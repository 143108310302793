import React, { type FC, useContext, useState } from 'react'
import { Card, CardContent, Typography, Grid, Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, TextField, DialogContentText, FormControl, InputLabel, Select, MenuItem, Autocomplete, IconButton, Skeleton } from '@mui/material'
import axios from 'axios'
import ConfigContext from '../ConfigContext/Config'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { UserContext } from '../Auth/Auth'
import { PhoneNumber } from '../../classes/PhoneNumber'

interface Template {
  Description: string
  Name: string
  StepID: string
}

interface IncidentDetailsProps {
  details: any
  templates: Template[]
  setDetails: any
  formData: any
  selectIsLoading: boolean
}

type IncidentData = {
  incidentNumber: string;
  description: string;
  templateName?: string;
  templateSiteID?: string;
  templateDescription?: string;
};

const IncidentDetails: FC<IncidentDetailsProps> = ({ details, formData, setDetails, templates, selectIsLoading }) => {
  console.log('details: ', details);
  const { properties } = useContext(ConfigContext)
  const directlineUrl = properties.baseUrls.directline + '/api/saas/' + properties.directline.key
  const [open, setOpen] = useState(false)
  const [description, setDescription] = useState('')
  const [template, setTemplate] = useState<Template>(null)
  const [action, setAction] = useState('')

  const handleClickOpen = (action: string) => {
    console.log('opening: ', action)
    setAction(action)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const resetPopup = () => {
    setDescription('')
    setTemplate(null)
  }

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value)
  }

  const handleTemplateChange = (value) => {
    console.log(value)
    setTemplate(value)
  }

  const handleSubmit = () => {
    const data = {
      name: action,
      data: {
        description
      } as IncidentData,
      userId: formData.contact
    } 

    switch (action) {
      case 'updateIncident':
        data.data.incidentNumber = details.Number;
        break
      case 'closeIncident':
        data.data.incidentNumber = details.Number;
        break
      case 'assignIncident':
        data.data.incidentNumber = details.Number;
        data.data.templateName = template.Name
        data.data.templateSiteID = template.StepID
        data.data.templateDescription = template.Description
        break
      case 'createIncident':
        data.data.templateName = template.Name
        data.data.templateSiteID = template.StepID
        data.data.templateDescription = template.Description
        break
      default:
        break
    }

    axios.post(`${directlineUrl}/directline/event`, data)
    resetPopup()
    setOpen(false)
  }

  const handleBackClick = () => {
    setDetails(null)
  }

  return (
    <>
    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="8px">
      <Typography variant="h6" align='left'>Incident Details</Typography>
      {details && !details.Message && (
        <Box display="flex" alignItems="center">
          <Button variant="contained" color="primary" style={{ fontSize: '8px', marginRight: '5px' }} onClick={() => { handleClickOpen('updateIncident') }}>
            Update
          </Button>
          <Button variant="contained" color="secondary" style={{ fontSize: '8px', marginRight: '5px' }} onClick={() => { handleClickOpen('assignIncident') }}>
            Re-assign
          </Button>
          <Button variant="contained" color="error" style={{ fontSize: '8px'}} onClick={() => { handleClickOpen('closeIncident') }}>
            Close
          </Button>
          <IconButton onClick={handleBackClick} style={{ borderRadius: '2px'}}>
            <ArrowBackIcon />
            <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '1 rem', fontWeight: 600, marginLeft: '5px'}}>
            Return
          </Typography>
          </IconButton>

        </Box>
      )}
    </Box>
      <Box style={{ height: 'calc(100% - 50px)', width: '100%', overflow: 'auto' }}>
        {details && !details.Message ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1" color="textSecondary" align='left'><strong>Number:</strong> {details.Number}</Typography>
                <Typography variant="body1" color="textSecondary" align='left'><strong>Submit Date:</strong> {details.SubmitDate.substring(0, 10).replace('_', '')}</Typography>
                <Typography variant="body1" color="textSecondary" align='left'><strong>Status:</strong> {details.Status}</Typography>
                <Typography variant="body1" color="textSecondary" align='left'><strong>Summary:</strong> {details.Summary}</Typography>
                <Typography variant="body1" color="textSecondary" align='left'><strong>Site:</strong> {details.Site}</Typography>
                <Typography variant="body1" color="textSecondary" align='left'><strong>Priority:</strong> {details.Priority}</Typography>
                <Typography variant="body1" color="textSecondary" align='left'><strong>Company:</strong> {details.Company}</Typography>
              </Grid>
              <Grid item xs={6}>
                <>
                  <Typography variant="body1" color="textSecondary" align='left'><strong>Worklog Number:</strong> {details.WorkLogNumber}</Typography>
                  <Typography variant="body1" color="textSecondary" align='left'><strong>Worklog Description:</strong> {details.WorkLogDescription}</Typography>
                  <Typography variant="body1" color="textSecondary" align='left'><strong>Worklog Detailed Description:</strong> {details.WorkLogDetailedDescription}</Typography>
                </>
              </Grid>
            </Grid>
          </>
        ) : (
          selectIsLoading ? (
            <Grid container spacing={2}>
            <Grid item xs={6}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
          </Grid>
          ) : (
            <Box display="flex" flexDirection="column" justifyContent="center" flexGrow={1} alignItems="center" height='100%'>
            <Typography variant="h5" color="textSecondary" align='center'>
              No Incident Selected
            </Typography>
            <Typography variant="body1" color="textSecondary" align='center' style={{ marginTop: '8px' }}>
              Please select an incident from the list to view its details.
            </Typography>
            <Button variant="outlined" color="error" style={{ marginTop: '16px' }} onClick={() => { handleClickOpen('createIncident') }}>
              Create a Incident
            </Button>
            <div style={{height: "20px"}}></div>
          </Box>
          ))
        }
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle>
          {action === 'updateIncident' && 'Update Incident'}
          {action === 'closeIncident' && 'Close Incident'}
          {action === 'assignIncident' && 'Re-Assign Incident'}
          {action === 'createIncident' && 'Create Incident'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the description for the incident.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            value={description}
            onChange={handleDescriptionChange}
          />
          {(action === 'assignIncident' || action === 'createIncident') && (
            <Autocomplete
              id="template-selector"
              options={templates}
              getOptionLabel={(option) => option.Name}
              renderInput={(params) => <TextField {...params} label="Template" variant="outlined" />}
              onChange={(event, value) => { handleTemplateChange(value) }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default IncidentDetails
