import { Box, Paper, Typography } from '@mui/material'
import React, { useState, type FC } from 'react'
import CustomerDetailsPanel from '../CustomerDetailsPanel/CustomerDetailsPanel'
import HighIncidentDetails from '../HighIncidentDetails/HighIncidentDetails'
import HighPriorityList from '../HighPriorityList/HighPriorityList'
import IncidentDetails from '../IncidentDetails/IncidentDetails'
import IncidentList from '../IncidentList/IncidentList'
import AgentIncidentList from '../AgentIncidentList/AgentIncidentList'
import NotificationTray from '../NotificationTray/NotificationTray'
import WebChat from '../webchat/webchat'

interface ReactWebChatPageProps {
  formData: any
  botStyles: any
  message: any
  onShowModal: any
  onShowTimeoutModal: any
}

const ReactWebChatPage: FC<ReactWebChatPageProps> = ({ formData, botStyles, message, onShowModal, onShowTimeoutModal }) => {
  const [customerDetails, setCustomerDetails] = useState(null)
  const [incidents, setIncidents] = useState([])
  const [agentIncidents, setAgentIncidents] = useState([])
  const [HighPriorityIncidents, setHighPriorityIncidents] = useState([])
  const [incidentDetail, setIncidentDetail] = useState(null)
  const [highIncidentDetail, setHighIncidentDetail] = useState(null)
  const [templates, setTemplates] = useState([])
  const [selectIncidentIsLoading, setSelectIncidentIsLoading] = useState(false)
  const [selectHighIncidentIsLoading, setSelectHighIncidentIsLoading] = useState(false)

  const handleCustomerDetails = (customer) => {
    setCustomerDetails(customer)
  }

  const handleIncidents = (incidents) => {
    setIncidents(incidents)
  }

  const handleAgentIncidents = (incidents) => {
    setAgentIncidents(incidents)
  }

  const handleHighPriorityIncidents = (incidents) => {
    setHighPriorityIncidents(incidents)
  }

  const handleIncidentDetail = (incident) => {
    setIncidentDetail(incident)
    setSelectIncidentIsLoading(false)
  }

  const handleHighIncidentDetail = (incident) => {
    setHighIncidentDetail(incident)
    setSelectHighIncidentIsLoading(false)
  }

  const handleTemplateList = (templates) => {
    setTemplates(templates)
  }

  return (
  <Box display="flex" flexDirection="column" style={{ overflow: 'hidden' }}>
    <Box display="flex" style={{ width: '100%' }}>
      <Box style={{ flex: '1',  textAlign: 'center' }}>
        <Typography variant="h6">Customer Details</Typography>
      </Box>
      <Box style={{ flex: '1',  textAlign: 'center' }}>
        <Typography variant="h6">Agent Assistant</Typography>
      </Box>
      <Box style={{ flex: '1',  textAlign: 'center' }}>
        <Typography variant="h6">Company Details</Typography>
      </Box>
    </Box>

    <Box display="flex" style={{ overflow: 'hidden' }}>
      {/* Left Panel */}
      <Box display="flex" flexDirection="column" style={{ flex: '1', overflow: 'hidden' }}>
        <Box component={Paper} p={2} m={1} style={{ flex: '1 1 auto', overflow: 'auto', height: '27vh' }}>
          <CustomerDetailsPanel
            customer={customerDetails} />
        </Box>
        <Box component={Paper} m={1} style={{ flex: '1 1 auto', height: '27vh', overflow: 'hidden' }}>
          <IncidentList formData={formData} tickets={incidents} setIsLoading={setSelectIncidentIsLoading} />
        </Box>
        <Box component={Paper} p={1} m={1} style={{ flex: '1 1 auto', height: '27vh', overflow: 'hidden' }}>
          <IncidentDetails formData={formData} details={incidentDetail} setDetails={setIncidentDetail} templates={templates} selectIsLoading={selectIncidentIsLoading} />
        </Box>
      </Box>

      {/* Middle Panel */}
      <Box display="flex" flexDirection="column" style={{ flex: '1', overflow: 'hidden', height: '88vh' }}>
        <Box component={Paper} p={2} m={1} style={{ flex: '1 1 auto', overflow: 'auto' }}>
          <WebChat
            styleOptions={botStyles}
            formData={formData}
            onShowModal={onShowModal}
            onShowTimeoutModal={onShowTimeoutModal}
            startMessage={message}
            onCustomerDetails={handleCustomerDetails}
            onAgentIncidents={handleAgentIncidents}
            onIncidents={handleIncidents}
            onHighPriorityIncidents={handleHighPriorityIncidents}
            onIncidentDetail={handleIncidentDetail}
            onHighIncidentDetail={handleHighIncidentDetail}
            onTemplateList={handleTemplateList}
          />
        </Box>
      </Box>

      {/* Right Panel */}
      <Box display="flex" flexDirection="column" style={{ flex: '1', overflow: 'hidden' }}>
        <Box component={Paper} p={1} m={1} style={{ flex: '1 1 auto', overflow: 'hidden', height: '27vh' }}>
        <AgentIncidentList formData={formData} tickets={agentIncidents} setIsLoading={setSelectIncidentIsLoading} />
        </Box>
        <Box component={Paper} m={1} style={{ flex: '1 1 auto', overflow: 'hidden', height: '27vh' }}>
          <HighPriorityList formData={formData} tickets={HighPriorityIncidents} setIsLoading={setSelectHighIncidentIsLoading}/>
        </Box>
        <Box component={Paper} p={1} m={1} style={{ flex: '1 1 auto', overflow: 'hidden', height: '27vh' }}>
          <HighIncidentDetails formData={formData} details={highIncidentDetail} setDetails={setIncidentDetail} templates={templates} selectIsLoading={selectHighIncidentIsLoading} />
        </Box>
      </Box>
    </Box>
  </Box>
  )
}

export default ReactWebChatPage
