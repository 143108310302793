import React, { createContext, useContext, useEffect, useState } from 'react';
import configContext from '../ConfigContext/Config';
import { CognitoIdentityProviderClient, InitiateAuthCommand, InitiateAuthCommandOutput, RespondToAuthChallengeCommand, SignUpCommand } from '@aws-sdk/client-cognito-identity-provider';
import { v4 as uuidv4 } from 'uuid';
import {
  CognitoUser,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import _ from 'lodash';

interface Agent {
  name: string,
  surname: string,
  internetEmail: string,
  corporateEmail: string,
  message: string
}

interface Session {
  user: Agent
}

const UserContext = createContext({
  session: null,
  setSession: (session: Session | null) => { },
  authSignUp: (data: any) => new Promise((resolve, reject) => { }),
  initiateAuth: (data: any) => new Promise((resolve, reject) => { }),
  respondFirstChallenge: (response: any, answer: "phone" | "email") => new Promise((resolve, reject) => { }),
  respondSecondChallenge: (data: InitiateAuthCommandOutput, userId:any, username: string, otp: string, type: "phone" | "email") => new Promise((resolve, reject) => { }),
  getSession: () => new Promise((resolve, reject) => { }),
  logout: () => { }
});

const Auth = props => {
  const [session, setSession] = useState<Session | null>(null)
  const {cognito, features, properties} = useContext(configContext)
  const [companyType, setCompanyType] = useState('Tigerbrands');

  useEffect(() => {
    const storedUser = localStorage.getItem('session')
    const storedTimestamp = localStorage.getItem('timestamp')
  
    if (storedUser && storedTimestamp) {
      const currentTime = new Date().getTime()
      const expiryTime = new Date(JSON.parse(storedTimestamp)).getTime() + 24 * 60 * 60 * 1000
  
      if (currentTime > expiryTime) {
        localStorage.removeItem('session')
        localStorage.removeItem('timestamp')
      } else {
        setSession(JSON.parse(storedUser))
      }
    }
  }, []);

  let client, UserPool = null;

  if (features.cognito) {
    if (cognito.companies[companyType].REGION && cognito.companies[companyType].USER_POOL_ID && cognito.companies[companyType].APP_CLIENT_ID) {
      client = new CognitoIdentityProviderClient({ region: cognito.companies[companyType].REGION });
      UserPool = new CognitoUserPool({UserPoolId: cognito.companies[companyType].USER_POOL_ID, ClientId: cognito.companies[companyType].APP_CLIENT_ID});
    } else {
      console.log("some cognito values are null: ", cognito)
    }
  } else {
    console.log("cognito is not enabled.")
  }

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = UserPool ? UserPool.getCurrentUser() : null;
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        });
      } else {
        resolve(null);
      }
    })
  }

  const authSignUp = async (data: any) => {
    return await new Promise(async (resolve, reject) => {
      try {
        if (!client) {
          reject(new Error("Authentication mechanism disabled, please contact support if this doesn't sound right."))
        }
        if (data.companyType !== "") {
          setCompanyType(data.companyType);
        }
        let response = await client.send(new SignUpCommand({
          ClientId: cognito.companies[companyType].APP_CLIENT_ID,
          Username: uuidv4(),
          Password: `Wc1@user${uuidv4()}`,
          UserAttributes: [
              {
                  Name: "phone_number",
                  Value: data.type == "phone" ? data.username : ""
              },
              {
                  Name: "email",
                  Value: data.type == "email" ? data.username : ""
              },
              {
                  Name: "name",
                  Value: data.name
              },
          ],
          ClientMetadata: {
            "company": properties.id,
          }
      }));
      console.log("response signup: ", JSON.stringify(response));
      resolve(response);
    } catch (error) {
      reject(error);
    }
    });
  }

  const initiateAuth = async (data: any) => {
    return await new Promise(async (resolve, reject) => {
      try {
      let response = await client.send(new InitiateAuthCommand({
        AuthFlow: 'CUSTOM_AUTH',
        ClientId: cognito.companies[companyType].APP_CLIENT_ID,
        AuthParameters: {
          USERNAME: data.username
        },
        ClientMetadata: {
          "company": properties.id
        }
      }));
        resolve(response)
    } catch (error) {
      reject(error);
    }
    });
  }

  const respondFirstChallenge = async (response: any, answer: "phone" | "email") => {
    return await new Promise(async (resolve, reject) => {
      try {
        const challengeResponse = await client.send(new RespondToAuthChallengeCommand({
            ChallengeName: response.ChallengeName,
            ClientId: cognito.companies[companyType].APP_CLIENT_ID,
            Session: response.Session,
            ChallengeResponses: {
                USERNAME: response.ChallengeParameters?.USERNAME ?? "",
                ANSWER: answer
            },
            ClientMetadata: {
              "company": properties.id
            }
        }));
        resolve(challengeResponse);
      } catch (error) {
        reject(error);
      }
    });
  }

  const respondSecondChallenge = async (data: InitiateAuthCommandOutput, userId:any, username: string, otp: string, type: "phone" | "email") => {
    const challengeResponse = await client.send(new RespondToAuthChallengeCommand({
        ChallengeName: "CUSTOM_CHALLENGE",
        ClientId: cognito.companies[companyType].APP_CLIENT_ID,
        Session: data.Session,
        ChallengeResponses: {
            USERNAME: username,
            ANSWER: otp
        },
        ClientMetadata: {
            type,
            "company": properties.id
        }
    }));
    const cognitoUser = new CognitoUser({Username: userId, Pool: UserPool});
    const AccessToken = new CognitoAccessToken({
      AccessToken: challengeResponse.AuthenticationResult.AccessToken,
    });
    const IdToken = new CognitoIdToken({ IdToken: challengeResponse.AuthenticationResult.IdToken });
    
    const RefreshToken = new CognitoRefreshToken({
        RefreshToken: challengeResponse.AuthenticationResult.RefreshToken,
    });
    
    const sessionData = {
        IdToken,
        AccessToken,
        RefreshToken,
    };
    const userSession = new CognitoUserSession(sessionData);
    cognitoUser.setSignInUserSession(userSession);
    localStorage.setItem("validSession", "true");
    return challengeResponse;
  }

  const logout = () => {
    const user = UserPool ? UserPool.getCurrentUser() : null;
    if (user) {
      user.signOut();
    }
    localStorage.removeItem('chat-session');
    localStorage.removeItem('validSession');
  }

  return (
  <UserContext.Provider value={{ session, authSignUp, initiateAuth, respondFirstChallenge, respondSecondChallenge, getSession, setSession, logout }}>
    {props.children}
  </UserContext.Provider>
  );
}

export { Auth, UserContext };
